@import "~@kaizen/deprecated-component-library-helpers/styles/layout";
@import "~@kaizen/component-library/styles/responsive";

.container {
  $container-padding: $ca-grid * 0.5;

  @include ca-padding(
    $start: $container-padding,
    $end: $container-padding,
    $top: $container-padding,
    $bottom: $container-padding
  );
}

.row {
  display: flex;

  @include ca-media-mobile {
    flex-direction: column;
  }
}

.metricTypeInputContainer {
  flex-grow: 1;
  max-width: 200px;

  @include ca-media-mobile {
    @include ca-margin($bottom: $ca-grid);
  }
}

.startTargetInputContainer {
  display: flex;

  @include ca-margin($start: auto);
  max-width: 300px;

  & > div {
    @include ca-margin($end: $ca-grid * 0.5);
  }

  & > :last-child {
    @include ca-margin($end: 0);
  }

  @include ca-media-mobile {
    @include ca-margin($start: unset);
  }
}

.footer {
  display: flex;

  @include ca-media-mobile {
    flex-direction: column-reverse;
  }
}

.validationMessageContainer {
  min-height: 48px;
  flex: 1;

  & > :first-child {
    margin: 0;
    height: 100%;
  }

  ul {
    @include ca-margin($start: $ca-grid * 0.75);
    list-style-position: inside;
    margin: 0;
  }

  ul,
  li {
    list-style-type: unset;
  }

  li > span {
    @include ca-margin($start: $ca-grid * -0.25);
  }
}

.actions {
  display: flex;
  justify-content: flex-end;

  @include ca-margin($start: auto);

  @include ca-media-mobile {
    @include ca-margin($bottom: $ca-grid);
  }
}

.action {
  @include ca-margin($end: $ca-grid * 0.25);

  &:last-child {
    @include ca-margin($end: 0);
  }
}
