@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.incomingAlignmentsContainer {
  display: flex;
}

.incomingAlignmentsLabel {
  @include ca-margin($top: $ca-grid, $bottom: $ca-grid * 0.5);
}

.incomingAlignmentCount {
  @include ca-margin($end: $ca-grid * 2);
}
