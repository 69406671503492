@import "~@kaizen/design-tokens/sass/spacing";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-md;
  margin-right: 0;
  margin-left: 0;
}

.title {
  flex-grow: 0;
  width: 50%;
}

.completion {
  flex-grow: 1;
  margin-right: 0;
  margin-left: $spacing-xl;
}
