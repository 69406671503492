@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/component-library/styles/border";
@import "~@kaizen/deprecated-component-library-helpers/styles/type";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

$IconButtonWidth: 48px;

.item {
  margin-bottom: $ca-grid * 0.5;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.avatar {
  margin-right: $ca-grid/2;
  display: flex;
  flex: 0 0 auto;
}

.textContainer {
  border-radius: $ca-border-radius * 2;
  display: flex;
  flex: 1 1 auto;
  line-height: 1.5;
  padding: 0.5 * $ca-grid $IconButtonWidth 0.5 * $ca-grid 0.75 * $ca-grid;
  position: relative;
  white-space: pre-wrap;

  .button {
    display: none;
  }
}

.textContainerEditable:hover {
  cursor: pointer;
  background-color: $color-gray-100;

  .button {
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.textContainerEmpty {
  opacity: 0.75;
}

.editingItem {
  margin-bottom: $ca-grid * 0.5;
  display: flex;
  flex-direction: column;
}

.editContainer {
  display: flex;
  align-items: center;
}

.textField {
  flex: 1;
}

// overwrite the margin-bottom
.textField > div {
  margin-bottom: 0;
}

.buttonContainer {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  margin-top: $ca-grid / 4;
}

.isIndented {
  margin-right: $IconButtonWidth;
}

.descriptionContainer {
  display: flex;
}
