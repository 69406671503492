@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/design-tokens/sass/spacing";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.container {
  @include ca-margin($top: $ca-grid, $start: $ca-grid, $end: $ca-grid);
  display: flex;
  flex-direction: column;

  & h3 {
    text-align: left;
  }
}

.progress {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include ca-margin($bottom: $ca-grid);
}

.progressSlider {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include ca-margin($bottom: $ca-grid);

  h4 {
    line-height: unset;
  }
}

.slider {
  flex-grow: 2;

  @include ca-margin($start: $spacing-md);
}

.owners {
  align-self: stretch;

  @include ca-margin($bottom: $ca-grid);
}

.comments {
  @include ca-margin($top: $ca-grid * 2);
}

.statusInfoContainer {
  @include ca-margin($bottom: $ca-grid);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & h5 {
    @include ca-margin($bottom: $ca-grid * 0.5, $top: $ca-grid);
  }
}

.status {
  flex-basis: 170px;
  margin-top: 1.5rem;
}

.status label {
  margin-bottom: 0.75rem;
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include ca-margin($top: $ca-grid);
}

.actions {
  @include ca-margin($top: 20px, $bottom: 20px);
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.goalAlignmentsLabel {
  @include ca-margin($top: $ca-grid);
}

.goalDetailTitleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: $ca-grid / 2;
}

.goalDetailTitle {
  @include ca-padding($top: $ca-grid / 3, $end: $ca-grid);
}

.detailContent {
  display: flex;

  @include ca-margin($bottom: 1rem);
}

.detailContentIcon {
  @include ca-margin($end: 1rem);
  width: 15px;
  height: 15px;
  color: $color-purple-800;
  opacity: 0.75;
}

.deleteButton {
  // This is needed to provide override for the button submenu in the `ButtonMenu`
  // until we refactor the `MoreButtonMenu` to use the proper kaizen component this !important is required
  color: $color-red-600 !important; /* stylelint-disable-line declaration-no-important */
}
