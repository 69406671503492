// Transitional code - Talk to #team_unify if you have any questions.
// stylelint-disable-next-line
:global {
  /* This is to target the legacy navigation bar until the
    unified navigation is fully rolled out. */
  .ZenHeader--enabled {
    [data-automation-id="Application_Navigation"] > button {
      opacity: 0;
      pointer-events: none;
    }
  }

  /* Until the title block rolls out on every page, we need to hide
    the mobile navigation button whenever this file is imported. */
  .legacy__navigaiton-trigger {
    display: none;
  }
}
