@import "~@kaizen/design-tokens/sass/color";

.tableContainer {
  overflow: auto;
}

.tableContainerFullWidth {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: 98vw;
}

.cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.stickyLeft {
  position: sticky;
  z-index: 2;
}

.flagTableCell {
  z-index: 2;
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

.headerCellContentWrapper {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.employeeTableHeaderCell,
.employeeTableCell {
  border-right: 2px solid $color-gray-300;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    box-shadow: 2px 0px 4px 1px #524e5640;
  }
}

.extraPaddingLeft.extraPaddingLeft {
  padding-left: 40px;
}

.canSort {
  cursor: pointer;
}

.sortIcon {
  color: rgba($color-purple-800-rgb, 0.45);

  & > svg {
    vertical-align: middle;
  }
}

.sortIcon--sorted {
  color: $color-purple-800-rgb;
}

.cellHighlighted {
  background-color: $color-blue-100;
}
