@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/design-tokens/sass/shadow";
@import "../variables";
@import "~@kaizen/component-library/styles/animation";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";
@import "~@kaizen/component-library/styles/layers";
@import "~@kaizen/component-library/styles/responsive";
@import "~@kaizen/deprecated-component-library-helpers/styles/color";

.drawer {
  z-index: $ca-z-index-fixed + 2; // two more than nav, one more than dossier
  background-color: white;
  bottom: 0;
  box-shadow: $shadow-large-box-shadow;
  position: fixed;
  right: $drawer-peek;
  top: 0;
  transition:
    transform $ca-duration-rapid $ca-ease-in,
    right $ca-duration-rapid $ca-ease-in;
  width: $drawer-width;
  will-change: right, transform;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;

  &.drawerOpen {
    transform: translateX(0);
    /* stylelint-disable-next-line declaration-no-important */
    right: 0 !important;
  }
}

.drawerHeading {
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  flex: none;
  display: flex;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid rgba($black, 0.1);
  padding: ($ca-grid / 3) 0;

  :global(.js-focus-visible) & {
    // hide native focus ring when :focus but not :focus-visible
    &:focus {
      outline: none;
    }

    // show custom focus ring when :focus-visible
    &:global(.focus-visible) {
      border: 2px solid $color-blue-500;
    }
  }

  .drawerHeadingElement {
    padding: ($ca-grid / 3) ($ca-grid / 2) ($ca-grid / 3) 0;
  }
}

.drawerToggle {
  align-items: center;
  display: block;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  transition: transform $ca-duration-rapid $ca-ease-in;
  width: $drawer-peek;

  .drawerOpen & {
    transform: rotateY(180deg);
  }
}

.drawerContent {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}

.drawerFooter {
  flex: none;
  margin-top: auto;
  min-height: 50px;
  box-shadow: $shadow-large-box-shadow;
}

@include layout-navigation-visible {
  .drawer {
    z-index: $ca-z-index-fixed - 1; // one less than nav
    top: $navbar-height;
  }
}

@include mobile-view {
  .drawer {
    z-index: $ca-z-index-fixed - 1; // one less than Kaizen TitleBlock to ensure any mobile controls are visible, one less than nav
  }
}
