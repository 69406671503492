@import "~@kaizen/design-tokens/sass/color";

.employeeCell {
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-flow: row nowrap;
}

.notesTooltip {
  color: $color-blue-500;
  position: absolute;
  top: 0;
  right: 0;
}
