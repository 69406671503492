@import "../../styles/_variables";

.Button--plain {
  color: @CTA_COLOR;

  &:hover,
  &:active,
  &:focus {
    color: @CTA_HOVER_COLOR;
  }
}

.Button--link {
  text-transform: capitalize;
}

.Button--secondary {
  &:hover {
    color: @CTA_HOVER_COLOR;
    border-color: @CTA_HOVER_COLOR;
  }
}
