@import "~@kaizen/design-tokens/sass/border";
@import "~@kaizen/component-library/styles/border";
@import "~@kaizen/component-library/styles/mixins/forms";
@import "../../styles/utils";
@import "../../styles/focusable";

.root {
  cursor: pointer;
  outline: none;
}

.focusRectangle {
  @include on-focus {
    @include focus-outline;
  }
}

.focusRounded {
  position: relative;

  // Copied from the kaizen library, maybe we should use a reusable mixin instead
  :global(.js-focus-visible) &:global(.focus-visible)::after {
    // Makes the border appear above all content
    z-index: 1;

    $focus-ring-offset: calc(
      -1 * ((#{$border-focus-ring-border-width} * 2) + 1px)
    );
    content: "";
    position: absolute;
    background: transparent;
    border-radius: $border-focus-ring-border-radius;
    border-width: $border-focus-ring-border-width;
    border-style: $border-focus-ring-border-style;
    border-color: $ca-border-color-focus;
    inset: $focus-ring-offset $focus-ring-offset $focus-ring-offset
      $focus-ring-offset;
  }
}
