// TODO: Import Navigation Height from design
@import "@kaizen/design-tokens/sass/layout";

// Loyout Settings
$minimum-supported-body-width: 375px;

// These may be included in design-tokens at some point.
$page-buffer-width-small: 12px;
$page-buffer-width-large: 28px;

// Dossier Settings
$dossier-desktop-max-width: 600px;
$small-dossier-desktop-max-width: 350px;

// Title Block Height
$mobile-titleblock-height: 56px;

// Drawer Settings
$drawer-width: 250px;
$drawer-peek: 75px;

// NavBar Settings
$navbar-height: $layout-navigation-bar-height;
$mobile-drawer-height: $layout-mobile-actions-drawer-height;

@mixin layout-breakpoint-large-up {
  @media (min-width: $layout-breakpoints-large) {
    @content;
  }
}

@mixin layout-navigation-visible {
  @media (min-width: #{$ca-breakpoint-tablet}) {
    @content;
  }
}

@mixin layout-navigation-drawer-visible {
  @media (max-width: $layout-breakpoints-medium - 1px) {
    @content;
  }
}

@mixin layout-dossier-should-not-overlap {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin mobile-view {
  @media (max-width: $ca-breakpoint-mobile) {
    @content;
  }
}
