@import "../../styles/_variables";

.ResponsiveWrapper {
  width: 100%;
  background-color: @BACKGROUND_COLOR;
}

.ResponsiveWrapper--container {
  width: 100%;
  max-width: @CONTAINER_WIDTH_WIDE;
  padding: 45px 40px 40px 40px;
  margin: 0 auto;

  @media (max-width: @MOBILE_WIDTH) {
    padding: 0 20px;
  }
}
