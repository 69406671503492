@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.container {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textfield {
  flex-grow: 1;
}

.avatar {
  @include ca-margin($end: 10px);
}

.details {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.actionsContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
