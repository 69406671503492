@import "@kaizen/design-tokens/less/typography";

@import "@kaizen/design-tokens/less/color";

@import "../../styles/_variables";

.ProgressBar {
  position: relative;
  border-radius: 3px;
  background-color: @color-gray-300;

  font-family: @typography-paragraph-body-font-family;
  font-weight: @typography-paragraph-body-font-weight;
  font-size: @typography-paragraph-body-font-size;
  line-height: @typography-paragraph-body-line-height;
  letter-spacing: @typography-paragraph-body-letter-spacing;

  &--completion-wrapper {
    height: 100%;
    border-radius: 3px;
    overflow: hidden;
  }

  &--completion {
    height: 100%;
    color: white;
    text-align: left;
    background-color: @color-purple-600;
    display: flex;
    align-items: center;

    &-empty {
      color: @color-purple-800;
    }

    &-fill {
      color: @color-purple-800;
    }

    &-full {
      flex-direction: row-reverse;
    }
  }

  &--text {
    margin: 0 5px;
  }
}
