@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.container {
  display: flex;
  flex-direction: column;
}

.heading {
  @include ca-margin($bottom: $ca-grid / 2);
}

.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @include ca-margin($bottom: 15px);
}

.item {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @include ca-margin($bottom: 5px, $end: 5px);
}
