@import "../variables";
@import "~@kaizen/design-tokens/sass/layout";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.contentPadding {
  padding-left: ($ca-grid / 2);
  padding-right: ($ca-grid / 2);
  margin: 0 auto;
  @include layout-breakpoint-large-up {
    padding-left: $layout-content-side-margin;
    padding-right: $layout-content-side-margin;
    max-width: $layout-content-max-width + ($layout-content-side-margin * 2);
  }
  @media (max-width: $layout-breakpoints-medium) {
    overflow: scroll;
  }
}
