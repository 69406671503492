@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/design-tokens/sass/typography";

:global {
  .flatpickr-current-month {
    color: $color-purple-800;
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months {
    font-weight: 400;
    margin: 0;
    background-color: transparent;
  }

  .flatpickr-months .flatpickr-month {
    background-color: $color-gray-200;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    svg {
      fill: $color-blue-500;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .flatpickr-weekdays {
    background-color: $color-gray-200;
  }

  span.flatpickr-weekday {
    background-color: $color-gray-200;
    font-size: $typography-paragraph-extra-small-font-size;
    opacity: 0.5;
    text-transform: uppercase;
    color: $color-purple-800;
  }

  .flatpickr-day.today {
    background-color: $color-gray-200;
    color: $color-blue-500;
  }

  .flatpickr-day {
    &.selected,
    &.startRange,
    &.endRange {
      &,
      &.inRange,
      &:focus,
      &:hover,
      &.prevMonthDay,
      &.nextMonthDay {
        background: $color-blue-500;
        box-shadow: none;
        color: white;
        border-color: $color-blue-500;
      }

      // Avoid adding extra fill because it overflows in multimonth mode
      &.startRange + .endRange:not(:nth-child(7n + 1)) {
        box-shadow: -10px 0 0 $color-blue-500;
      }
    }
  }

  .flatpickr-days {
    border-left: 1px solid rgba($color-gray-600-rgb, 0.1);
    border-right: 1px solid rgba($color-gray-600-rgb, 0.1);
  }

  .flatpickr-calendar {
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
  }

  .flatpickr-calendar.hasTime .flatpickr-time {
    border: 1px solid rgba($color-gray-600-rgb, 0.1);
  }

  .flatpickr-time input {
    color: $color-purple-800;
  }

  .flatpickr-calendar.arrowTop {
    &::after,
    &::before {
      border-bottom-color: $color-gray-200;
    }
  }

  .flatpickr-calendar.arrowBottom {
    &::after,
    &::before {
      border-top-color: $color-gray-200;
    }
  }

  /*
    flatpickr has the following styles

    .flatpickr-current-month .numInputWrapper {
      width: 6ch;
      width: 7ch\0;
    }

    Not sure what is the point of width: 7ch\0 but it's invalid.
    Unfortunately UglifyJsPlugin strips out width: 6ch, so we manually
    declare it here.
  */
  .flatpickr-current-month .numInputWrapper {
    width: 6ch;
  }
}
