@import "@kaizen/design-tokens/less/color";

@import "./_variables";

.icon() {
  @width: 32px;

  display: inline-block;
  width: @width;
  height: @width;
  padding: 0;
  margin: 10px;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.icon-unread {
  &::after {
    content: "•";
    color: @color-orange-500;
    font-size: 24px;
  }
}

.icon-incomplete {
  &::after {
    content: "○";
    color: @color-orange-500;
    font-style: normal;
    font-size: 21px;
  }
}

.icon-done {
  &::after {
    content: "○";
    visibility: hidden;
    font-size: 24px;
  }
}

.icon-share {
  .icon;
  height: 20px;
  width: 18px;
  background-size: cover;
  background-image: url("../static/images-2017/icons/icon-share.png");
}

.icon-star-yes {
  .icon;
  height: 16px;
  width: 16px;
  background-image: url("../static/images-2017/icons/icon-star-yes-2.png");
  background-size: cover;
}

.icon-star-no {
  .icon;
  height: 16px;
  width: 16px;
  background-image: url("../static/images-2017/icons/icon-star-no-2.png");
  background-size: cover;
}

.icon-envelope {
  .icon;
  background-size: 28px;
  background-image: url("../static/images-2017/icons/icon-envelope.png");
}

.icon-heart {
  .icon;
  width: 18px;
  height: 16px;
  background-image: url("../static/images-2017/icons/icon-heart.png");
  background-size: cover;
}

.icon-heart-filled {
  .icon;
  width: 18px;
  height: 16px;
  background-image: url("../static/images-2017/icons/icon-heart-filled.png");
  background-size: cover;
}
