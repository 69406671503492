@import "~@kaizen/component-library/styles/responsive";
@import "~@kaizen/component-library/styles/grid";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.filtersBar {
  display: flex;
  flex-direction: row;

  @include ca-media-mobile {
    flex-direction: column;
    width: 100%;
  }
}

.select {
  display: flex;
  flex-direction: column;
}

.datepickerLabelContainer {
  display: flex;

  @include ca-margin($bottom: $ca-grid / 2);
}

.datepickerLabel {
  @include ca-margin($start: $ca-grid / 2);
}

.filterContainer {
  @include ca-margin($start: $ca-grid / 2, $end: $ca-grid / 2);
}

.filterBarContainer {
  @include ca-margin($bottom: $ca-grid);
}
