.clearfix {
  &::before,
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

.flex1 {
  flex: 1 1 auto;
}

// A version of :focus, that only applies if it is detected that the user is using
// the keyboard via the tab key.
// Note, this function is documented under docs/accessibility.md. If you change this
// function, update the docs accordingly.
@mixin on-focus {
  :global(.js-focus-visible) &:global(.focus-visible) {
    @content;
  }
}

@mixin button-reset {
  appearance: none;
  background: transparent;
  border: none;
  font: inherit;
  margin: 0;
  padding: 0;
  transition: none;
}

@mixin anchor-reset {
  text-decoration: none;
  color: inherit;
  display: block;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

@mixin ca-margin($start: 0, $end: 0, $top: null, $bottom: null) {
  margin: $top $end $bottom $start;

  &[dir="rtl"],
  [dir="rtl"] & {
    margin-right: $start;
    margin-left: $end;
  }
}

@mixin ca-padding($start: 0, $end: 0, $top: null, $bottom: null) {
  padding: $top $end $bottom $start;

  &[dir="rtl"],
  [dir="rtl"] & {
    padding-right: $start;
    padding-left: $end;
  }
}
