@import "~@kaizen/design-tokens/sass/border";
@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/design-tokens/sass/spacing";
@import "~@kaizen/design-tokens/sass/typography";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.container {
  @include ca-margin($bottom: $spacing-lg);
}

.labelWrapper {
  & > label {
    font-weight: $typography-paragraph-small-font-weight;
  }
}

.progressBarInputContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  @include ca-margin($bottom: $ca-grid * 0.5);
}

.progressBarContainer {
  flex-grow: 1;
  position: relative;

  @include ca-margin($end: $ca-grid * 0.75);
}

.progressBarTickMarkContainer {
  top: $ca-grid * 0.75;
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  small {
    color: $color-purple-800;
  }
}

.inputContainer {
  width: 20%;
  max-width: 150px;
  min-width: 80px;

  & > div {
    margin-bottom: 0;
  }
}

.errorBanner {
  @include ca-margin($top: $spacing-lg);
  padding: $spacing-xs;

  width: 100%;
  height: $spacing-lg;
  background-color: $color-red-100;
  border-radius: $border-borderless-border-radius;
  display: flex;
  flex-direction: row;
  align-items: center;

  color: $color-red-500;

  :first-child {
    @include ca-margin($end: $spacing-xs);
  }
}
