@import "~@kaizen/design-tokens/sass/typography";
@import "~@kaizen/design-tokens/sass/color";

.primitiveTable {
  width: 100%;
}

// .primitiveTbody {}
// .primitiveTr {}

.primitiveTh {
  color: $color-gray-600;
  font-weight: $typography-heading-6-font-weight;
  font-size: $typography-heading-6-font-size;
  font-family: $typography-heading-6-font-family;
  line-height: $typography-heading-6-line-height;
  letter-spacing: $typography-heading-6-letter-spacing;
  padding: 10px 18px;
  border-radius: 0;
  background: white;
  border-bottom: 1px solid $color-gray-300;
  text-align: left;
}

.primitiveThead {
  // We need this to reset Materialize.css
  border: 0;
}

.primitiveTd {
  vertical-align: middle;
  padding: 15px 18px;
  border-radius: 0;
  background: white;
  border-bottom: 1px solid $color-gray-200;
}
