@import "@kaizen/design-tokens/less/color";

@import "../../styles/_shared";

.EmptyState {
  width: 100%;
  background-color: @color-gray-300;
  border: 1px solid #d7d7db;
  padding: 31px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  @media (max-width: @GMAIL_FRAME_WIDTH) {
    flex-direction: column;
  }

  @media (max-width: @MOBILE_WIDTH) {
    flex-direction: column;
  }
}

.EmptyState--img {
  max-width: 100%;
  max-height: 300px;
  @media (max-width: @MOBILE_WIDTH) {
    max-height: 170px;
  }
}

.EmptyState--image-container {
  min-width: 300px;
  flex: 0 40%;
  @media (max-width: @MOBILE_WIDTH) {
    min-width: 0px;
  }
}

.EmptyState--body {
  color: @TEXT_COLOR_SECONDARY;
  .ca-type-ideal-lede();
  margin-top: 0;
  white-space: pre-wrap;
  flex-grow: 1;

  a {
    color: @color-blue-500;
  }
}

.EmptyState--text-container {
  display: flex;
  flex-direction: column;
  flex: 1 50%;
  padding: 0 30px;

  @media (max-width: @MOBILE_WIDTH) {
    padding: 0px;
  }

  h2 {
    text-align: left;
    margin-bottom: 10px;
  }

  p {
    text-align: left;
    color: @color-purple-800;
  }
}
