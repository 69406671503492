@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include ca-margin($start: 10px);
  color: $color-purple-800;
}

.name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.jobTitle {
  opacity: 0.7;
}

.profileHeaderDeactivated {
  color: red;
}
