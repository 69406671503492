@import "./_variables";

/**
* Campification
*/

body {
  .ca-type-ideal-small();
}

.btn-flat.btn-bordered {
  .ca-type-ideal-button();
}

/**
 * In-between a heading and body text - a little larger than body text but
 * lighter than a header.
 *
 * Example:
 *
 * 		<h4>Company Settings</h4>
 * 		<p class="subheading">
 * 			Adjust your company's avatar, title, etc.
 * 		</p>
 */
.subheading {
  font-size: 1.125rem;
  line-height: 1.5;
  color: @TEXT_COLOR_SECONDARY;
  margin: 0.4rem 0 1rem;
  text-align: center;
}

/**
 * Chops off the bottom margin of a heading to make it jive better with a
 * `.subheading`.
 *
 * Example:
 *
 * 		<h4 class="with-subheading">Company Settings</h4>
 * 		<p class="subheading">
 * 			Adjust your company's avatar, title, etc.
 * 		</p>
 */
.with-subheading {
  .page-header;
  margin-bottom: 0;
}

/**
 * Shaded lighter than regular text.
 *
 * Example:
 *
 * 		<p class="secondary-text">
 * 			This may or may not interest you
 * 		</p>
 */
.secondary-text {
  color: @TEXT_COLOR_SECONDARY;
}

.page-header {
  margin-top: 0;
  margin-bottom: 10px;
}
