@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";
@import "~@kaizen/component-library/styles/animation";
@import "~@kaizen/deprecated-component-library-helpers/styles/color";

.container {
  position: relative;
}

.actions {
  display: flex;
  justify-content: space-between;

  @include ca-margin($top: $ca-grid / 2);
}

@mixin input-icon($color, $reversed) {
  color: $color;

  .withReversed & {
    color: $reversed;
  }
}

.error {
  @include input-icon(
    $data-viz-unfavorable,
    $ca-palette-input-validation-negative
  );
  @include ca-animation-fade(in);
}

.popover {
  z-index: 2;
}
