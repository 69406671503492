@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/design-tokens/sass/spacing";

.truncatedText {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userNameWrapper {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: $spacing-xs;
}

.userName {
  max-width: 100%;
  font-weight: 600;
  overflow: hidden;
}

.jobTitle {
  color: rgba($color-purple-800-rgb, 0.7);
}

.userInformationWrapper {
  overflow: hidden;
}
