// TODO: Do we need this file? Probs not
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.selectOption {
  display: flex;
  align-items: center;
}

.labelContainer {
  @include ca-margin($start: $ca-grid * 0.5);
}
