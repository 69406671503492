// Sync with ./AppearanceAnim.tsx
$anim-duration-ms: 0.2s;

.defaultHiddenState {
  opacity: 0;
  pointer-events: none;
  transition: opacity $anim-duration-ms ease-out;
  will-change: opacity;
}

.visibleState {
  opacity: 1;
  pointer-events: initial;
}
