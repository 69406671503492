@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.numberInput {
  unicode-bidi: plaintext;
  text-align: right;
}

.unitIconContainer {
  @include ca-padding($bottom: $ca-grid * 0.125, $top: $ca-grid * 0.125);

  color: $color-gray-500;
  height: 100%;
}
