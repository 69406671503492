@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.outgoingAlignmentsLabel {
  @include ca-margin($top: $ca-grid * 0.5);
}

.emptyState {
  @include ca-margin($top: $ca-grid * 0.5, $bottom: $ca-grid * 0.5);
}

.emptyStateTitle {
  @include ca-margin($top: $ca-grid, $bottom: $ca-grid * 0.5);
}
