@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";
@import "~@kaizen/component-library/styles/border";

$IconButtonWidth: 48px;

.item {
  margin-bottom: $ca-grid * 0.5;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textContainer {
  border-radius: $ca-border-radius * 2;
  display: flex;
  flex: 1 1 auto;
  line-height: 1.5;
  padding: 0.5 * $ca-grid $IconButtonWidth 0.5 * $ca-grid 0.75 * $ca-grid;
  position: relative;
  white-space: pre-wrap;

  .button {
    display: none;
  }
}

.textContainerEditable:hover {
  cursor: pointer;
  background-color: $color-gray-100;

  .button {
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.textContainerEmpty {
  opacity: 0.75;
}
