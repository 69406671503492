.publishPointsList {
  margin: 0;
  box-sizing: border-box;

  & > li {
    margin: 0.8rem;
    list-style: disc;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
