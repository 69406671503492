@import "@kaizen/design-tokens/less/color";

@import "./_shared";
@import "./_fonts";

@import "./lib/rc-tooltips-overrides.less";

@import "./_icons";
@import "./_typography";

/* Structure
====================================== */

html {
  height: 100%;
  max-height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: @FONT_SIZE;
}

body {
  background: @BACKGROUND_COLOR;
  height: 100%;
  max-height: 100%;
  line-height: @LINE_HEIGHT;
  color: @TEXT_COLOR;
  font-family: @FONT_FAMILY;
  font-size: @FONT_SIZE;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-feature-settings: normal; // Safari bug with 'kern' 1;
  font-kerning: normal;
}

#app {
  // prevent page from moving when swiping left/right
  @media (max-width: @MOBILE_WIDTH) {
    overflow-x: hidden;
  }
}

hr {
  // This global style was overriding some hr styles when appling the Divider component from kaizen.
  // Update it to have same style as Divider variant=content.
  // At some point we need to remove this but we will need to check other hr arround the code.
  border-top: 1px solid @color-gray-600;
}

input::placeholder,
textarea::placeholder,
input::-moz-focus-inner,
textarea::-moz-focus-inner {
  color: rgba(@color-purple-800-rgb, 0.8);
  opacity: 1;
}

.labeled-rule {
  position: relative;
  height: 2.5em;
  margin: 0 auto;
  p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -25px;
    background-color: #eee;
    padding: 0px 25px;
  }
}

// Normalize focus outline on Firefox
::-moz-focus-inner {
  border: 0;
}
:focus {
  outline: none;
}

/* Animations
====================================== */
.animated {
  transition: all 0.5s ease-out;
  animation-duration: 1s;
  animation-fill-mode: both;

  &.infinite {
    animation-iteration-count: infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse-size {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes raise {
  0% {
    transform: scaleY(0.01);
  }
  75% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

/* Utilities/Helpers/Mixins
====================================== */

.hide-on-mobile-only {
  @media (max-width: @MOBILE_WIDTH) {
    display: none;
  }
}

.hide-on-small-only {
  @media (max-width: @SMALL_SCREEN_WIDTH) {
    display: none;
  }
}

.cf {
  *zoom: 1;
  &::before,
  &::after {
    content: "";
    display: table;
  }
  &::after {
    clear: both;
  }
}
