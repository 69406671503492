@import "../../styles/_shared";

.Icon--container {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  // // hack for IE 11
  // position: absolute;
}

.Icon--container-no-image {
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;
}
