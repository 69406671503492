@import "~@kaizen/design-tokens/sass/typography";
@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

.calendar {
  @include ca-margin($bottom: $ca-grid * 0.5);
  display: flex;
  flex-direction: column;
  color: $color-purple-800;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.weekdays {
  display: flex;
  flex-direction: row;

  @include ca-margin($bottom: $ca-grid / 2);
}

.day {
  font-family: $typography-paragraph-body-font-family;
  font-weight: $typography-paragraph-body-font-weight;
  font-size: $typography-paragraph-body-font-size;
  line-height: $typography-paragraph-body-line-height;
  letter-spacing: $typography-paragraph-body-letter-spacing;

  @include ca-inherit-baseline;
  @include ca-margin($start: 1px, $end: 1px, $top: 1px, $bottom: 1px);
  padding: 0;
  border-style: none;
  border-radius: 3px;
  background-color: $color-white;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  outline: 0;

  &:disabled {
    background-color: $color-gray-100;
    opacity: 0.7;
  }

  &:hover {
    background-color: $color-blue-200;
  }
}

.row {
  &:last-child {
    border-bottom: 0;
  }

  display: flex;
  flex-direction: row;
}

.dayName {
  text-align: center;
  width: 42px;
}

.between {
  background-color: $color-blue-100;
  color: $color-blue-500;
}

.selected {
  background-color: $color-blue-500;
  color: white;
  border-color: $color-green-500;
}

.selected:hover {
  background-color: $color-blue-500;
}

.empty {
  border: none;

  @include ca-padding($start: 1px);
  background-color: $color-white;
}

.empty:disabled {
  background-color: $color-white;
}
