@import "@kaizen/design-tokens/less/typography";

.ca-type-ideal-heading() {
  font-family: @typography-paragraph-body-font-family;
  font-weight: @typography-paragraph-body-font-weight;
  letter-spacing: @typography-paragraph-body-letter-spacing;
  font-size: 1.125rem;
  line-height: 1.4;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.ca-type-ideal-lede() {
  font-family: @typography-paragraph-body-font-family;
  font-weight: 300;
  letter-spacing: @typography-paragraph-body-letter-spacing;
  font-size: @typography-paragraph-intro-lede-font-size;
  line-height: 1.5;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.ca-type-ideal-body() {
  font-family: @typography-paragraph-body-font-family;
  font-weight: 300;
  letter-spacing: @typography-paragraph-body-letter-spacing;
  font-size: @typography-paragraph-body-font-size;
  line-height: @typography-paragraph-body-line-height;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.ca-type-ideal-body-bold() {
  font-family: @typography-paragraph-body-font-family;
  font-weight: @typography-paragraph-body-font-weight;
  letter-spacing: @typography-paragraph-body-letter-spacing;
  font-size: @typography-paragraph-body-font-size;
  line-height: @typography-paragraph-body-line-height;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.ca-type-ideal-small() {
  font-family: @typography-paragraph-body-font-family;
  font-weight: 300;
  letter-spacing: @typography-paragraph-body-letter-spacing;
  font-size: @typography-paragraph-small-font-size;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.ca-type-ideal-small-bold() {
  font-family: @typography-paragraph-body-font-family;
  font-weight: @typography-paragraph-body-font-weight;
  letter-spacing: @typography-paragraph-body-letter-spacing;
  font-size: @typography-paragraph-small-font-size;
  line-height: 1.71428571;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.ca-type-ideal-notification() {
  font-family: @typography-paragraph-body-font-family;
  font-weight: 300;
  letter-spacing: @typography-paragraph-body-letter-spacing;
  font-size: 0.9375rem;
  line-height: 1.2;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.ca-type-ideal-label() {
  font-family: @typography-paragraph-body-font-family;
  font-weight: 500;
  letter-spacing: 0.04166667em;
  font-size: 0.75rem;
  line-height: 2;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.ca-type-ideal-button() {
  font-family: @typography-button-primary-font-family;
  font-weight: 500;
  letter-spacing: @typography-button-primary-letter-spacing;
  font-size: @typography-button-primary-font-size;
  line-height: 2;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.kz-typography-heading-1() {
  font-family: @typography-heading-1-font-family;
  font-weight: @typography-heading-1-font-weight;
  letter-spacing: @typography-heading-1-letter-spacing;
  font-size: @typography-heading-1-font-size;
  line-height: @typography-heading-1-line-height;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.kz-typography-heading-2() {
  font-family: @typography-heading-2-font-family;
  font-weight: @typography-heading-2-font-weight;
  letter-spacing: @typography-heading-2-letter-spacing;
  font-size: @typography-heading-2-font-size;
  line-height: @typography-heading-2-line-height;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.kz-typography-heading-3() {
  font-family: @typography-heading-3-font-family;
  font-weight: @typography-heading-3-font-weight;
  letter-spacing: @typography-heading-3-letter-spacing;
  font-size: @typography-heading-3-font-size;
  line-height: @typography-heading-3-line-height;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
