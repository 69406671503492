@import "~@kaizen/design-tokens/sass/color";
@import "~@kaizen/design-tokens/sass/layout";
@import "@kaizen/design-tokens/sass/spacing";
@import "~@kaizen/design-tokens/sass/border";
@import "~@kaizen/deprecated-component-library-helpers/styles/layout";

$grid-size: 24px;
$box-width: 260px;
$line-width: $border-solid-border-width;
$line-color: $color-gray-400;

.treeScrollContainer {
  display: flex;
}

.tree {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.rootNodeContainer {
  display: flex;
  margin: 0 auto;
}

.rootChildRow {
  display: flex;
  margin: 0 auto;
  padding: $grid-size;

  > div {
    position: relative;
    padding: 0 $grid-size;

    &::before {
      content: "";
      position: absolute;
      top: -$grid-size;
      left: 0;
      width: 100%;
      border-top: $line-width solid $line-color;
      box-sizing: border-box;
      background-color: $line-color;
      height: $line-width;
    }
  }

  > div:only-child {
    width: $box-width + 2 * $grid-size;

    &::before {
      display: none;
    }
  }

  > div:first-child:not(:only-child) {
    &::before {
      left: calc(#{$grid-size} + (#{$box-width} / 2));
    }
  }

  > div:last-child:not(:only-child) {
    &::before {
      width: calc(#{$grid-size} + (#{$box-width} / 2));
    }
  }
}

.resetButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: -$grid-size * 2.5;
  margin-bottom: $grid-size;
}

.controls {
  position: fixed;
  bottom: $grid-size;
  display: flex;
  right: ($grid-size / 2);

  @media (min-width: $layout-breakpoints-large) {
    right: $layout-content-side-margin;
  }

  @media (min-width: ($layout-content-max-width + ($layout-content-side-margin * 2))) {
    transform: translateX(-100%);
    left: calc(50% + #{$layout-content-max-width} / 2);
    right: unset;
  }
}

$btn-size: $spacing-xl;
$btn-border-radius: $border-solid-border-radius;
$btn-border-width: $border-solid-border-width;

.btnClass {
  border: $btn-border-width solid $color-gray-500;
  height: $btn-size;
  width: $btn-size;
  background: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $color-gray-100;
  }
}

.plusBtn {
  border-right: 0;
  border-radius: $btn-border-radius 0 0 $btn-border-radius;
}

.minusBtn {
  border-radius: 0 $btn-border-radius $btn-border-radius 0;
}

.toolTip {
  height: $btn-size;
  width: $btn-size;
  display: flex;
  align-items: center;
  justify-content: center;
}
