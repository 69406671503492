@import "../_variables";

.rc-tooltip {
  opacity: 1;
  color: @TEXT_COLOR;
  padding: 0;
  border: 1px solid @PURPLE_BORDER_COLOR;
  border-radius: @MEDIUM_BORDER_RADIUS;
  background-color: transparent;
  box-shadow: @CARD_HOVER_SHADOW;
  z-index: 99; // Should be less than the z-index of Modals
}

.rc-tooltip-content {
  border-radius: @MEDIUM_BORDER_RADIUS;
}

.rc-tooltip-inner {
  padding: 0;
  border: none;
  border-radius: @MEDIUM_BORDER_RADIUS;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  border-bottom-color: @WHITE;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  border-top-color: @WHITE;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
  border-left-color: @WHITE;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  border-right-color: @WHITE;
}
