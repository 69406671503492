@import "./NavigationTree";

%baseNode {
  margin-bottom: $grid-size;
  width: $box-width;

  display: flex;
  justify-content: center;

  cursor: pointer;
  position: relative;
}

%lineUp {
  &::before {
    content: "";
    position: absolute;
    background-color: $line-color;
    width: $line-width;
    left: 50%;
    top: -$grid-size;
    height: $grid-size;
  }
}

%lineDown {
  &::after {
    content: "";
    position: absolute;
    background-color: $line-color;
    width: $line-width;
    left: 50%;
    bottom: -$grid-size;
    height: $grid-size;
  }
}

%lineLeft {
  &::before {
    position: absolute;
    content: "";
    background-color: $line-color;
    height: $line-width;
    top: $grid-size * 2;
    left: -$grid-size;
    width: $grid-size;
  }
}

%lineFullVertical {
  &::after {
    position: absolute;
    content: "";
    background-color: $line-color;
    width: $line-width;
    left: -$grid-size;
    top: -$grid-size;
    height: calc(100% + #{$grid-size});
  }
}

%lineHalfVertical {
  &::after {
    position: absolute;
    content: "";
    background-color: $line-color;
    width: $line-width;
    left: -$grid-size;
    top: -$grid-size;
    height: $grid-size * 3;
  }
}

%lineFullVerticalParent {
  &::after {
    position: absolute;
    content: "";
    background-color: $line-color;
    width: $line-width;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.childNodes {
  width: 100%;
  margin-left: $grid-size;
  position: relative;
}

.nodeContainer {
  position: relative;
  padding: 0 $grid-size;

  /* stylelint-disable-next-line selector-not-notation */
  &:not(:only-of-type):not(:last-of-type) {
    @extend %lineFullVerticalParent;
  }

  .node {
    @extend %baseNode;
    @extend %lineFullVertical;
    @extend %lineLeft;
  }
}

.firstLevelNodeContainer {
  position: relative;
  padding: 0 $grid-size;

  &::after {
    display: none;
  }

  .node {
    @extend %baseNode;
    @extend %lineUp;
  }
}

.rootNode {
  @extend %baseNode;
  @extend %lineDown;

  height: unset;
}

.childNodes > :last-child .node {
  @extend %lineHalfVertical;
}
